<!--
 * @Author: LXG
 * @Date: 2021-05-14
 * @Editors: LXG
 * @LastEditTime: 2021-06-27
 * @Description: 政策办事指南内容
-->
<template>
  <div class="policy-guide-content">
    <Anchor v-if="guideType == 1" show-ink>
      <AnchorLink
        v-for="(item, index) in anchorList"
        :key="index"
        :href="'#' + item.href"
        :title="item.title"
      >
      </AnchorLink>
    </Anchor>
    <!-- 简版 -->
    <div v-if="guideType == 0">
      <div class="pgc-item">
        <h2 class="pgc-title">一、政策内容</h2>
        <div class="easyItem-content">
          <p>{{ policyBaseData.supportstandard }}</p>
          <el-table
            class="marg-tb-1 easy-zcnr-table"
            :data="cpu_zzbzList"
            stripe
            border
            size="medium"
            row-class-name="table-sbdx-row"
            header-row-class-name="table-sbdx-header"
          >
            <el-table-column
              type="index"
              label="序号"
              width="55px"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="bldx"
              label="办理对象"
              width="100px"
              align="center"
            >
              <template slot-scope="scope">
                {{ cpu_noText(getDictLabel("permit_sertype", scope.row.bldx)) }}
              </template>
            </el-table-column>
            <el-table-column prop="qtyq" label="其他要求" header-align="center">
              <template slot-scope="scope">
                <div v-html="cpu_qtyqText(scope.row)"></div>
              </template>
            </el-table-column>
            <el-table-column
              prop="gssz"
              label="资金测算公式"
              header-align="center"
            >
              <template slot-scope="scope">
                <div class="table-sbdx-cell-gssz">
                  <p>
                    {{ cpu_noText(scope.row.gssz) }}
                  </p>
                  <el-button
                    v-if="scope.row.csfs.includes('1')"
                    size="small"
                    type="primary"
                    @click="calculateCapital(scope.row)"
                    >计算</el-button
                  >
                  <!-- <TjIcon name="jisuanqi-m"></TjIcon> -->
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="zzxe"
              label="资金限额"
              width="100px"
              align="center"
            >
              <template slot-scope="scope">
                <span v-if="scope.row.zzxe"> {{ scope.row.zzxe }}万元 </span>
              </template>
            </el-table-column>
            <el-table-column
              prop="dept_name"
              label="受理部门"
              width="100px"
              align="center"
            >
              <template slot-scope="scope">
                {{ cpu_noText(scope.row.dept_name) }}
              </template>
            </el-table-column>
          </el-table>
          <p>
            <span :style="{ color: variableStyle['color-red'] }">注：</span>
            <span
              >本政策对企业行业、产业、注册类型、企业资质、经济指标、荣誉信息、资质认证等方面无要求。</span
            >
          </p>
        </div>
      </div>
    </div>
    <!-- 详版 -->
    <div v-if="guideType == 1" class="particular">
      <div id="jbxx" class="pgc-item">
        <h2 class="pgc-title">基本信息</h2>
        <TwoColumnTable
          :items="cpu_baseList"
          labelWidth="80px"
        ></TwoColumnTable>
      </div>
      <div
        id="zzdx"
        class="pgc-item"
        v-if="this.policyId != 'e3d1fa8f6e5c4620ab28ba63d1b272fb' && this.policyId != '7d7cd03229954c269756ef4e4df06a5c'"
      >
        <h2 class="pgc-title">资助对象</h2>
        <div class="particular-content">
          <TwoColumnTable
            :items="cpu_zzdxList"
            labelWidth="120px"
          ></TwoColumnTable>
        </div>
      </div>
      <div id="zzbz" class="pgc-item">
        <h2 class="pgc-title">
          {{
            this.policyId != "e3d1fa8f6e5c4620ab28ba63d1b272fb" && this.policyId != '7d7cd03229954c269756ef4e4df06a5c'
              ? "资助标准"
              : "奖补标准"
          }}
        </h2>
        <div class="particular-content">
          <el-table :data="cpu_zzbzList" stripe border size="medium">
            <el-table-column
              prop="zzqx"
              label="标准要求"
              header-align="center"
            ></el-table-column>
            <el-table-column
              prop="zzxe"
              label="资金限额"
              width="200px"
              align="center"
              v-if="this.policyId != 'e3d1fa8f6e5c4620ab28ba63d1b272fb' && this.policyId != '7d7cd03229954c269756ef4e4df06a5c'"
            >
              <template slot-scope="scope">
                <span v-if="scope.row.zzxe"> {{ scope.row.zzxe }}万元 </span>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
<!--      <div id="sbtj" class="pgc-item" v-if="this.policyId == 'e3d1fa8f6e5c4620ab28ba63d1b272fb' || this.policyId == '7d7cd03229954c269756ef4e4df06a5c'">-->
      <div id="sbtj" class="pgc-item" >
        <h2 class="pgc-title">申报条件</h2>
        <div
          class="text-i-28 particular-content"
          v-html="cpu_noText(policyBaseData.serviceflow)"
        ></div>
      </div>
      <div id="sblc" class="pgc-item">
        <h2 class="pgc-title">申报流程</h2>
        <div class="particular-content particular-sblc">
          <div v-if="policyBaseData.serviceflow || policyBaseData.runflowimg">
<!--            <div class="text-i-28" v-html="policyBaseData.serviceflow" v-if="this.policyId != 'e3d1fa8f6e5c4620ab28ba63d1b272fb' && this.policyId != '7d7cd03229954c269756ef4e4df06a5c'"></div>-->
            <div v-html="policyBaseData.runflowimg"></div>
          </div>
          <p v-else>{{ cpu_noText() }}</p>
        </div>
      </div>
      <div id="sqcl" class="pgc-item">
        <h2 class="pgc-title">申请材料</h2>
        <MaterialTable :list="materialData"></MaterialTable>
      </div>
      <div id="zxfs" class="pgc-item">
        <h2 class="pgc-title">咨询方式</h2>
        <div
          class="particular-content"
          v-html="cpu_noText(policyBaseData.advisory_tel)"
        ></div>
      </div>
      <div id="zcyj" class="pgc-item">
        <h2 class="pgc-title">政策依据</h2>
        <div
          class="text-i-28 particular-content"
          v-html="cpu_noText(policyBaseData.supportstandard)"
        ></div>
      </div>

      <!--            <div
                id="ckbl"
                class="pgc-item"
            >
                <h2 class="pgc-title">窗口办理</h2>
                <div class="particular-content">
                    <div v-html="cpu_noText(policyBaseData.address)"></div>
                    <div
                        style="margin-top: 10px;"
                        v-html="cpu_noText(policyBaseData.serviceway)"
                    ></div>
                </div>
            </div>-->
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// import TjIcon from '@/components/tjIcon/TjIcon'
import TwoColumnTable from "@/components/twoColumnTable";
import MaterialTable from "./components//guide/materialTable";
import { formatDate } from "@/utils/dateHandler.js";
import variableStyle from "@/styles/variable.scss";

export default {
  name: "PolicyGuideContent",
  components: {
    // TjIcon,
    TwoColumnTable,
    MaterialTable,
  },
  props: {
    policyId: {
      type: String,
    },
    // 办事指南版本
    //     '0': 简版
    //     '1': 详版
    guideType: {
      type: [Number, String],
      default: 1,
    },
    // 政策原文信息
    policySourceData: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      variableStyle: variableStyle,
      easyInfo: {
        infoList: [{ title: "政策内容", valueKey: "supportstandard" }],
      },
      policyBaseData: {}, // 政策基本信息
      policyZzbzData: [], // 政策资助标准信息(可能多个资助标准)
      qxId: "", // 同一个政策不同资助标准的唯一ID 资助ID?
      policyPortraitData: {}, // 政策画像信息
      materialData: [], // 政策事项对应材料信息
      anchorList: [
        { href: "jbxx", title: "基本信息" },
        { href: "zzdx", title: "资助对象" },
        { href: "zzbz", title: "资助标准" },
        { href: "sblc", title: "申报流程" },
        { href: "sqcl", title: "申请材料" },
        { href: "zxfs", title: "咨询方式" },
        { href: "zcyj", title: "政策依据" },
        // { href: 'ckbl', title: '窗口办理' },
      ],
      anchorList2: [
        { href: "jbxx", title: "基本信息" },
        { href: "zzbz", title: "奖补标准" },
        { href: "sbtj", title: "申报条件" },
        { href: "sblc", title: "申报流程" },
        { href: "sqcl", title: "申请材料" },
        { href: "zxfs", title: "咨询方式" },
        { href: "zcyj", title: "政策依据" },
        // { href: 'ckbl', title: '窗口办理' },
      ],
    };
  },
  computed: {
    ...mapGetters({
      getDictLabel: "dictionary/getDictLabel",
      storeguideId: "getCurrentGuideId",
    }),
    cpu_noText() {
      return function (val) {
        return val || "--";
      };
    },
    /**
     * @description 申报对象 其他要求 显示文本
     * @param {Object} item 资助标准
     * @return {String}
     */
    cpu_qtyqText() {
      return function (item) {
        let htmlText = "";
        if (item.jjzb) {
          htmlText += `<p>${item.jjzb}</p>`;
        }
        if (item.ryxxyq && item.zzrzyq) {
          htmlText += `<p>${item.ryxxyq} / ${item.zzrzyq}</p>`;
        } else if (item.ryxxyq || item.zzrzyq) {
          htmlText += `<p>${item.ryxxyq || item.zzrzyq}</p>`;
        }

        return this.cpu_noText(htmlText);
      };
    },
    /**
     * @description: 资助标准列表
     * @return {Array}
     */
    cpu_zzbzList() {
      // 如果是从首页政策列表点击了具体的一个资助标准进来的
      // 页面会携带qxId过来，作为标识资助标准的唯一ID
      // 只显示这条资助标准
      // 否则，显示所有资助标准
      if (this.qxId) {
        let item = this.policyZzbzData.find((cv) => cv.qxid === this.qxId);
        if (item) return [item];
      }
      return this.policyZzbzData;
    },
    /**
     * @description: 基本信息列表
     * @param {*}
     * @return {*}
     */
    cpu_baseList() {
      let list = [];
      if (this.policyId != "e3d1fa8f6e5c4620ab28ba63d1b272fb" && this.policyId != '7d7cd03229954c269756ef4e4df06a5c') {
        list = [
          { title: "政策分类", content: this.cpu_noText() },
          {
            title: "支持方式",
            content: this.cpu_noText(
              this.getDictLabel("sq_policy_zjlx", this.policyPortraitData.zcfs)
            ),
          },
          { title: "资金限额", content: this.cpu_noText() },
          { title: "办理时限", content: this.cpu_noText() },
          {
            title: "受理部门",
            content: this.cpu_noText(this.policyBaseData.dept_name),
          },
          {
            title: "政策年份",
            content: this.cpu_noText(this.policySourceData.year),
          },
          { title: "政策层级", content: "德阳市" },
          { title: "申报期限", content: this.cpu_noText() },
        ];
        // 项目类别
        if (this.policyPortraitData.gjz) {
          list[0].content = this.policyPortraitData.gjz
            .split(",")
            .filter((cv) => cv)
            .map((cv) => this.getDictLabel("sq_policy_xmlb", cv))
            .join("/");
        }
        // 资金限额
        //     如果有多个资助标准，取最高限额
        var zjxe = 0;
        if (this.qxId) {
          zjxe = this.policyZzbzData.find((cv) => cv.qxid === this.qxId)?.zzxe;
        } else {
          for (let i = 0; i < this.policyZzbzData.length; i++) {
            zjxe = Math.max(zjxe, this.policyZzbzData[i].zzxe);
          }
        }
        zjxe && (list[2].content = zjxe + "万元");
        // 办理时限
        if (this.policyBaseData.permit_days) {
          var temp = this.policyBaseData.permit_days;
          switch (this.policyBaseData.common_attributes) {
            case "G":
              temp += "个工作日";
              break;
            case "Z":
              temp += "个自然日";
              break;
            default:
              temp += "个工作日";
              break;
          }
          list[3].content = temp;
        }
        // 申报期限
        list[7].content = `
            ${formatDate(this.policyBaseData.start_time, "yyyy年MM月dd日")}
            ~
            ${formatDate(this.policyBaseData.endtime, "yyyy年MM月dd日")}
            `;
      } else {
        list = [
          { title: "政策分类", content: this.cpu_noText() },
          {
            title: "支持方式",
            content: this.cpu_noText(
              this.getDictLabel("sq_policy_zjlx", this.policyPortraitData.zcfs)
            ),
          },
          { title: "政策层级", content: "德阳市" },
          { title: "有效期", content: this.cpu_noText() },
        ];
        // 项目类别
        if (this.policyPortraitData.gjz) {
          list[0].content = this.policyPortraitData.gjz
            .split(",")
            .filter((cv) => cv)
            .map((cv) => this.getDictLabel("sq_policy_xmlb", cv))
            .join("/");
        }
        // 申报期限
        list[3].content = `
            ${formatDate(this.policyBaseData.start_time, "yyyy年MM月dd日")}
            ~
            ${formatDate(this.policyBaseData.endtime, "yyyy年MM月dd日")}
            `;
      }

      return list;
    },
    /**
     * @description: 资助对象表格
     * @return {Array}
     */
    cpu_zzdxList() {
      return [
        {
          title: "注册要求",
          content: this.cpu_noText(this.policyPortraitData.zcyq),
        },
        {
          title: "注册地",
          content: this.cpu_noText(this.policyPortraitData.zcd),
        },
        {
          title: "纳税地",
          content: this.cpu_noText(this.policyPortraitData.nsd),
        },
        {
          title: "统计关系所在地",
          content: this.cpu_noText(this.policyPortraitData.tjgxszd),
        },
      ];
    },
  },
  methods: {
    /**
     * @description 计算资助标准对于企业的具体限额
     * @param {Object} item 资助标准
     */
    calculateCapital(item) {
      this.$message.warning("功能开发中");
    },
    /**
     * @description: 查询政策基本信息
     * @param {String} id 政策ID
     */
    getPolicyBaseData(id) {
      let url = "/dev-api/cfgpermitconfig/query/data/find/cfg_permit_info_list";
      let params = {
        sxid: id,
      };
      this.$httpApi
        .post(url, params)
        .then((res) => {
          console.log("getPolicyBaseData:", res);
          if (res.data && !!res.data.length) {
            this.policyBaseData = res.data[0];
          }
        })
        .catch((err) => {
          console.log("getPolicyBaseData error:", err);
        });
    },
    /**
     * @description: 查询政策资助标准信息
     * @param {String} id 政策ID
     */
    getPolicyZzbzData(id) {
      let url = "/dev-api/sqzdManager/data/find/sq_policy_project_zzqx_list";
      let params = {
        sxid: id,
      };
      this.$httpApi
        .post(url, params)
        .then((res) => {
          console.log("getPolicyZzbzData:", res);
          if (res.data && !!res.data.length) {
            this.policyZzbzData = res.data;
          }
        })
        .catch((err) => {
          console.log("getPolicyZzbzData error:", err);
        });
    },
    /**
     * @description: 查询政策画像信息
     * @param {String} id 政策ID
     */
    getPolicyPortraitData(id) {
      let url = "/dev-api/cfgpermitconfig/query/data/find/cfg_permit_zchx";
      let params = {
        sxid: id,
      };
      this.$httpApi
        .post(url, params)
        .then((res) => {
          console.log("getPolicyPortraitData:", res);
          if (res.data && !!res.data.length) {
            this.policyPortraitData = res.data[0];
          }
        })
        .catch((err) => {
          console.log("getPolicyPortraitData error:", err);
        });
    },
    /**
     * @description: 查询政策事项对应材料信息
     * @param {String} sxid 事项ID
     */
    getMaterialData(sxid) {
      let url =
        "/dev-api/cfgpermitconfig/query/data/find/cfg_permit_materiallist";
      let params = { sxid };
      this.$httpApi
        .post(url, params)
        .then((res) => {
          console.log("getMaterialData:", res);
          this.materialData = res.data || [];
        })
        .catch((err) => {
          console.log("getMaterialData error:", err);
        });
    },
  },
  created() {
    // if (this.policyId == "e3d1fa8f6e5c4620ab28ba63d1b272fb" || this.policyId == '7d7cd03229954c269756ef4e4df06a5c') {
      this.anchorList = this.anchorList2;
    // }
    this.$store.dispatch("dictionary/commonSetDic", {
      types: [
        "sq_policy_xmlb", // 项目类别
        "sq_policy_zjlx", // 扶持方式(支持方式)
        "permit_sertype", // 办理对象
        "sys_enterprise_registertype", // 注册类型
      ],
    });
    this.$route.query.qxId && (this.qxId = this.$route.query.qxId);
  },
  watch: {
    policyId: {
      immediate: true,
      handler: function (newVal) {
        if (newVal) {
          this.getPolicyBaseData(newVal);
          this.getPolicyPortraitData(newVal);
          this.getMaterialData(newVal);
          this.getPolicyZzbzData(newVal);
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.policy-guide-content {
  position: relative;
}
.pgc-item + .pgc-item {
  margin-top: 40px;
}
.pgc-title {
  margin-bottom: 10px;
  font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB",
    "Microsoft YaHei UI Light", "Microsoft YaHei UI", "Microsoft YaHei",
    "微软雅黑", Arial, sans-serif;
  font-weight: 700;
  color: #000000;
}
.easyItem-content {
  line-height: 2;
  text-indent: 28px;
}
.particular-content {
  line-height: 2;
  white-space: pre-wrap;
}
.text-i-28 {
  text-indent: 28px;
}
::v-deep .easy-zcnr-table {
  .table-sbdx-header {
    th {
      font-weight: normal;
      color: #ffffff;
      background-color: $col-theme;
    }
  }
  .table-sbdx-row {
    .table-sbdx-cell-gssz {
      display: -webkit-flex;
      display: flex;
      align-items: center;

      p {
        flex: 1;
        padding-right: 20px;
      }
      .el-button {
        padding: 5px;
      }
      // .tj-icon {
      //     font-size: 20px;
      //     color: $col-theme;
      //     cursor: pointer;
      // }
    }
  }
}
.particular-sblc {
  ::v-deep img {
    width: 100%;
    max-width: 700px;
  }
}
.particular {
  ::v-deep .el-table {
    .el-table__header-wrapper {
      th {
        font-weight: bold;
        color: #000000;
        background-color: #f0f8ff;
      }
    }
  }
}
::v-deep .ivu-anchor {
  position: fixed;
  background-color: #ffffff;
  .ivu-anchor-ink {
    display: none;
  }
  .ivu-anchor-ink-ball {
    width: 4px;
    height: 20px;
    border: none;
    border-radius: 0;
    // background-color: $col-theme;
    background-color: #3989c4;
    transform: translate(-50%, -2px);
  }
  .ivu-anchor-link {
    width: 140px;
    height: 40px;
    padding: 0px;
    margin-bottom: 6px;
    display: block;
    text-align: center;
    line-height: 40px;
    font-size: 16px;
    color: #333333;
    background-color: #f5f5f5;
    // &.ivu-anchor-link-active a {
    //     color: #3989c4;
    // }
  }
}
::v-deep {
  .ivu-anchor-link-active {
    background-color: #03b1ff !important;
    .ivu-anchor-link-title {
      color: #fff;
    }
  }
  .cell .title {
    background: #f0f8ff;
  }
}

@media screen and (min-width: 1366px) {
  ::v-deep .ivu-anchor {
    top: 42vh;
    left: calc(50% - 660px);
  }
}
@media screen and (min-width: 1600px) {
  ::v-deep .ivu-anchor {
    top: 38vh;
    left: calc(50% - 670px);
  }
}
@media screen and (min-width: 1920px) {
  ::v-deep .ivu-anchor {
    top: 35vh;
    left: calc(50% - 680px);
  }
}
@media screen and (max-width: 1365.98px) {
  ::v-deep .ivu-anchor {
    left: calc(50% + 512px - 100px);
    bottom: calc(#{$footerHeight} + 5vh);
  }
}
</style>
