<template>
    <div class="table-view">
        <el-table
            :data="list"
            style="width: 100%"
            height="400"
        >
            <el-table-column
                label="序号"
                width="55"
                align="center"
            >
                <template slot-scope="scope">
                    <div>{{ scope.$index + 1 }}</div>
                </template>
            </el-table-column>
            <el-table-column
                prop="clmc"
                label="材料名称"
            >
            </el-table-column>
            <el-table-column
                prop="clmc"
                label="材料形式"
                width="100"
            >
                <template slot-scope="scope">
                    <p>原件：{{scope.row.orinum||0}}</p>
                    <p>复印件：{{scope.row.copynum||0}}</p>
                </template>
            </el-table-column>
            <el-table-column
                prop="clmc"
                label="材料要求"
                width="100"
            >
                <template slot-scope="scope">
                    <p>{{scope.row.sfby==1?'必要':'不必要'}}</p>
                    <p v-if="scope.row.yq"><a @click="showYq(scope.row)">其他要求</a></p>
                </template>
            </el-table-column>
            <el-table-column
                prop="ybid"
                label="样表下载"
                width="120"
            >
                <template slot-scope="scope">
                    <a
                        v-if="scope.row.ybid"
                        @click="downloadTemplateFile(scope.row)"
                    >
                        <span>点击下载</span>
                        <i class="el-icon-download"></i>
                    </a>
                    <span v-else>--</span>
                </template>
            </el-table-column>
            <el-table-column
                label="其他信息"
                width="100"
            >
                <template slot-scope="scope">
                    <p v-if="scope.row.lyqd"><a @click="showLyqd(scope.row)">来源渠道</a></p>
                    <p v-if="scope.row.tbxz"><a @click="showTbxz(scope.row)">填报须知</a></p>
                    <p v-if="scope.row.bz"><a @click="showRemark(scope.row)">备注</a></p>
                </template>
            </el-table-column>
        </el-table>
        <el-dialog
            class="material-dialog"
            :visible.sync="dialogInfo.show"
            :title="dialogInfo.title"
            top="30vh"
        >
            <div>
                <TjRender :renderFn="dialogInfo.renderFn"></TjRender>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import TjRender from '@/components/tjRender/TjRender'

export default {
    name: "MaterialTable",
    components: {
        TjRender,
    },
    props: {
        list: {
            type: Array,
            default: function () {
                return [];
            },
        },
    },
    data() {
        return {
            dialogInfo: {
                show: false,
                title: '',
                renderFn: function (h) { },
            },
        };
    },
    computed: {
    },
    methods: {
        /**
         * @description: 下载模板文件(样表)
         * @param {Object} item 材料
         */
        downloadTemplateFile(item) {
            let download = document.createElement('a')
            download.setAttribute('target', '_blank')
            download.setAttribute('download', true)
            download.href = `${this.$httpApi.baseUrl}/dev-api/fileManageApi/common/fileView/${item.ybid}`
            download.click()
        },
        /**
         * @description: 展示要求
         * @param {Object} 材料
         */
        showYq(item) {
            this.dialogInfo.title = '材料要求'
            this.dialogInfo.renderFn = (h) => {
                return h('p', {}, item.yq)
            }
            this.dialogInfo.show = true
        },
        /**
         * @description: 展示来源渠道
         * @param {Object} item 材料
         */
        showLyqd(item) {
            this.dialogInfo.title = '来源渠道及说明'
            this.dialogInfo.renderFn = (h) => {
                return h('div', [
                    h('p', {}, item.lyqd),
                    h('p', {}, item.lyqdsm ? '说明：' + item.lyqdsm : '')
                ])
            }
            this.dialogInfo.show = true
        },
        /**
         * @description: 展示填报须知
         * @param {Object} item 材料
         */
        showTbxz(item) {
            this.dialogInfo.title = '填报须知'
            this.dialogInfo.renderFn = (h) => {
                return h('p', {}, item.tbxz)
            }
            this.dialogInfo.show = true
        },
        /**
         * @description: 展示备注
         * @param {Object} item 材料
         */
        showRemark(item) {
            this.dialogInfo.title = '备注'
            this.dialogInfo.renderFn = (h) => {
                return h('p', {}, item.bz)
            }
            this.dialogInfo.show = true
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep .material-dialog {
    .el-dialog__header {
        position: relative;
        font-weight: 700;

        &::after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 50%;
            width: calc(100% - 40px);
            border-bottom: 3px solid #000000;
            -webkit-transform: translate(-50%, 0);
            -moz-transform: translate(-50%, 0);
            -o-transform: translate(-50%, 0);
            -ms-transform: translate(-50%, 0);
            transform: translate(-50%, 0);
        }
    }
    .el-dialog__headerbtn {
        top: 0;
        right: 0;
        width: 32px;
        height: 32px;
        background-color: $col-theme;

        .el-dialog__close {
            color: #ffffff;
        }
    }
}
</style>