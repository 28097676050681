<template>
    <div class="policy-guide">
            <header class="banner"></header>
        <div class="def-container wrap-header">
            <h1 class="policy-name">{{childItem.name}}</h1>
            <!-- <img src="~@/assets/images/positionImg.png" alt="" style="">
             <el-breadcrumb separator-class="el-icon-arrow-right" style="font-size:18px;display:inline-block;margin-left:8px">
                <el-breadcrumb-item :to="{ path: '/'}">首页</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/policyProgram'}" v-if='linkType'>项目申报</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/policyMatchReport'}" v-if="navFlag==3">政策推送</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/searchList'}" v-if="navFlag==1">分类搜索</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/guideSuqiu'}">办事指南</el-breadcrumb-item>
             </el-breadcrumb> -->
            <div class="header_bottom">
                <div class="policy-infos">
                    <div
                        v-for="(item,index) in infoList"
                        :key="index"
                        class="policy-info-item"
                    >
                        <label class="info-label">{{item.label}}：</label>
                        <div class="info-value">
                            <div
                                v-if="item.valueHTML"
                                v-html="item.valueHTML()"
                            ></div>
                            <template v-else>{{childItem[item.valueKey]}}</template>
                        </div>
                    </div>
                    <div class="guide-version">
                    <span style="margin-right: 6px;">政策解读</span>
                    <el-radio-group
                        v-model="guideType"
                        size="mini"
                    >
                        <el-radio-button :label="1">详版</el-radio-button>
                        <el-radio-button :label="0">简版</el-radio-button>
                    </el-radio-group>
                </div>
                </div>
                <div class="guide-btns">
                    <!-- <el-button
                        type="primary"
                        :disabled="!cpu_canDeclare"
                        @click="goDeclare"
                    >一键申报</el-button> -->
                    <!-- <el-button
                        class="btn-collect"
                        :class="{'operate-collect--collect':collectItemId}"
                        @click="collectOrCancel"
                    >
                        <div class="collect_inner">
                            <i
                                v-show="collectItemId"
                                class="el-icon-star-on collect-icon"
                            ></i>
                            <i
                                v-show="!collectItemId"
                                class="el-icon-star-off collect-icon"
                            ></i>
                            <span class="collect-text">{{collectItemId?'已收藏':'收藏'}}</span>
                        </div>
                    </el-button> -->
                </div>
            </div>
        </div>
        <div class="def-container wrap-content">
            <div class="content_left">
                <!-- <div class="guide-version">
                    <h2>政策解读</h2>
                    <el-radio-group
                        v-model="guideType"
                        size="mini"
                    >
                        <el-radio-button :label="1">详版</el-radio-button>
                        <el-radio-button :label="0">简版</el-radio-button>
                    </el-radio-group>
                </div> -->
                <div class="policyHtml">
                   <div class="title"> <span class="titlename">政策原文</span>{{policySourceData.name}}</div>
                   <p v-if="policySourceData.content"  class="max-three-line policycontent" v-html="policySourceData.content.replace(/<[^>]+>/g, '')"
                   ></p>
                </div>
                
                <PolicyGuideContent
                    class="marg-tb-3"
                    :policyId="guideId"
                    :guideType="guideType"
                    :policySourceData="policySourceData"
                ></PolicyGuideContent>
            </div>
            <!-- <div class="content_right">
                <el-card
                    class="guide-card card-text"
                    shadow="never"
                >
                    <template v-slot:header>
                        <h3>
                            <i class="el-icon-document"></i>
                            政策原文
                        </h3>
                    </template>
                    <div class="card-text-content">
                        <p>
                            <a
                                v-if="policySourceData.queryurl&&policySourceData.queryurl!=='暂无'"
                                :href="policySourceData.queryurl"
                                target="_blank"
                            >
                                {{policySourceData.name}}
                            </a>
                            <span v-else>{{policySourceData.name}}</span>
                        </p>
                        <div class="content-type">政策资讯</div>
                    </div>
                    <div class="card-text-footer">
                        <span class="card-text-jg">{{policySourceData.fbjg}}</span>
                        <span>{{policySourceData.publictime}}</span>
                    </div>
                </el-card>
            </div> -->
        </div>
        <!-- <div
            ref="guideContainer"
            class="guide-container"
        >
            <div v-if="baseItem != null">
                <div class="menus">
                    <div class="g-right-part">
                        <div class="flex-row-between menu-content">
                            <div class="main-content">
                                <GuideCenter
                                    ref="guideCenter"
                                    :baseItem="baseItem"
                                ></GuideCenter>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
        <el-dialog
            title="该事项已经有暂存件，是否继续申报？"
            :visible.sync="dialogTableVisible"
        >
            <el-table
                :data="showTempDate"
                @row-click="stempDeclare"
            >
                <el-table-column
                    property="showName"
                    label="暂存事项"
                ></el-table-column>
                <el-table-column
                    property="createtime"
                    label="申报日期"
                ></el-table-column>
            </el-table>

            <span
                slot="footer"
                class="dialog-footer"
            >
                <el-button @click="dismissTempDialog">重新申报</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import PolicyGuideContent from './PolicyGuideContent'
// import GuideCenter from "./components/guide/guideCenter";
// import GuideRight from "./components/guide/guideRight";
import { Loading } from "element-ui";
import { formatDate } from '@/utils/dateHandler.js'
import variableStyle from '@/styles/variable.scss'

const NOW = new Date()

export default {
    name: "guide",
    components: {
        PolicyGuideContent,
        // GuideCenter,
        // GuideRight,
    },
    data() {
        return {
          navFlag:"",//面包屑导航显示
            infoList: [
                { label: '发布时间', valueKey: 'createTime' },
                {
                    label: '申报时间', valueHTML: () => {
                        let startTime = formatDate(this.childItem.startTime, 'date')
                        let endtime = formatDate(this.childItem.endtime, 'date')
                        // 剩余期限
                        let limitText = ''
                        if (NOW < new Date(this.childItem.startTime)) {
                            limitText = '<span>（未开始）</span>'
                        } else if (new Date(this.childItem.endtime) < NOW) {
                            limitText = `<span style="color: ${variableStyle['color-red']};">（已过期）</span>`
                        } else {
                            let time = new Date(this.childItem.endtime).getTime() - NOW.getTime()
                            time = parseInt(time / (24 * 60 * 60 * 1000))
                            limitText = `<span style="color: ${variableStyle['color-blue']};font-weight: bold;">（剩 ${time} 天）</span>`
                        }

                        return `<span>${startTime} 至 ${endtime}</span>${limitText}`
                    }
                },
            ],
            guideType: 1, // 办事指南显示版本{0:简版, 1:详版}
            offsetTop: 28,
            baseItem: undefined,
            tempProjects: [],
            showTempDate: [],
            dialogTableVisible: false,
            // 4  -> 1 审批类事项
            // 5  -> 2 扶持类事项
            // 6  -> 3 诉求类事项
            collectType: 6, //收藏接口传的type参数
            collectItemId: "",
            itemData: null,
            itemId: "", //当前事项id
            guideId: "",
            // 常见问题列表
            questionList: [
                {
                    question: '领取补贴需要符合哪些条件？',
                    answer: '需具备以下条件：已经入围漯河市级工业互联网平台；'
                },
                {
                    question: '如果符合条件，我能拿多少奖励资金？',
                    answer: '按照平台项目上一年投入30%给予资助，封顶1000万元，连续享受3年；'
                },
            ],
            policySourceData: {}, // 政策原文信息
            linkType:false
        };
    },
    computed: {
        ...mapGetters({
            userInfo: 'getUserInfo',
            childItem: "getCurrentDeclareItem",
            storeguideId: "getCurrentGuideId",
        }),
        /**
         * @description: 是否允许申报
         */
        cpu_canDeclare() {
            // 在申报日期范围内才允许申报
            if (
                (new Date(this.childItem.startTime) <= NOW) &&
                (NOW <= new Date(this.childItem.endtime))
            ) {
                return true
            }

            return false
        },
    },

    methods: {
        changeGuideType() {
            this.guideType ^= 1
            // 页面中 详版，用了iview的锚点
            // 锚点会给url添加上锚点位置id
            // 用 v-if 控制后，每次显示都会初始化到锚点位置
            // 限制清掉锚点位置
            if (this.guideType == 0) {
                this.$router.replace('/guideSuqiu')
            }

        },
        /* 转跳到精简办事指南 */
        gotoStreamline() {
            this.$router.push({
                path: "/guideSuqiuStreamline",
            });
        },
        loadData() {
            // console.log("guideId:", this.guideId);
            if (this.guideId) {
                let loadingInstance = Loading.service({
                    target: this.$refs.guideContainer,
                });
                //查询是否收藏
                this.isItemCollect();

                this.$nextTick(() => {
                    // 以服务的方式调用的 Loading 需要异步关闭
                    this.$httpApi
                        .get(`/dev-api/basicinfo/baseitem/${this.guideId}`, {})
                        .then((res) => {
                            // console.log("办事指南详情页（1）>>>", res, this.guideId);
                            loadingInstance.close();
                            if (res.code == 200) {
                                this.baseItem = res.data;
                                // console.log("办事指南详情页（2）>>>", this.baseItem);
                                let childItem = this.baseItem.itemList[0];
                                if (childItem) {
                                    this.$store.commit("updateCurrentProjectItem", null);
                                    this.$store.commit("updateCurrentDeclareItem", childItem);
                                }
                            }
                        })
                        .catch((err) => {
                        });
                });
            }
        },
        stempDeclare(row, column, event) {
            this.$store.commit("updateCurrentDeclareItem", null);
            this.$store.commit("updateCurrentProjectItem", row);
            this.$router.push({
                name: "declare",
            });
        },
        dismissTempDialog() {
            this.dialogTableVisible = false;
            let childItem = this.$refs.guideCenter.childItem;
            this.$store.commit("updateCurrentProjectItem", null);
            this.$store.commit("updateCurrentDeclareItem", childItem);
            this.getEnterpriseList();
        },
        /**
         * @description: 前往政策项目申报(new)
         */
        goDeclare() {
            this.$router.push({
                name: 'policyDeclaration',
                query: {
                    policyId: this.storeguideId,
                },
            })
        },
        gotoDeclare() {
            if (this.$store.getters.getUserInfo) {
                let loadingInstance = Loading.service({
                    target: this.$refs.guideContainer,
                });
                this.$httpApi
                    .get(`/dev-api/userBusiness/historyBizList`, {
                        identify: this.$store.getters.getUserInfo.shxydm,
                        sxid: this.guideId,
                    })
                    .then((res) => {
                        loadingInstance.close();
                        // if (res.code == 200 && res.rows.length != 0) {
                        //   this.showTempDate = [];
                        //   let resList = JSON.parse(JSON.stringify(res.rows));
                        //   /* 序列化 */
                        //   for (const item of resList) {
                        //     item.bsdata = JSON.parse(item.bsdata);
                        //     item.status = 6; // 待提交设置为6
                        //     item.sxmc = item.bsdata.baseinfo.sxmc;
                        //     item.showName = "暂存项目";
                        //     item.createtime = item.createtime.split("T")[0];
                        //     this.showTempDate.push(item);
                        //   }
                        //   this.dialogTableVisible = true;
                        //   return;
                        // }
                        let childItem = this.$refs.guideCenter.childItem;
                        this.$store.commit("updateCurrentProjectItem", null);
                        this.$store.commit("updateCurrentDeclareItem", childItem);
                        this.getEnterpriseList();
                    })
                    .catch((e) => {
                        loadingInstance.close();
                        this.$message({
                            type: "error",
                            message: "网络异常，请稍后重试",
                        });
                    });
            } else {
                this.$router.push({ name: "login" });
            }
        },
        /**
         * 企业用户基本信息列表
         */
        getEnterpriseList() {
            if (this.$store.getters.getUserInfo) {
                let url = "/dev-api/enterprise/baseInfo/list";
                // console.log("idxinxi....", this.$store.getters.getUserInfo)
                let params = {
                    pageSize: 100, // 页大小
                    pageNum: 0, // 页码
                    webEid: this.$store.getters.getUserInfo.id,
                };
                // console.log("企业用户基本信息列表params>>>", params)
                this.$httpApi
                    .get(url, params)
                    .then((res) => {
                        console.log("判断是否需要引导>>>", res);
                        if (res.code === 200 && res.rows.length == 0) {
                            // 如果长度为0则需要提示补充基本信息
                            this.$confirm("请您完善企业基本信息", "提示", {
                                confirmButtonText: "完善",
                                cancelButtonText: "取消",
                                type: "warning",
                            })
                                .then(() => {
                                    this.$router.push({
                                        path: "/workplace/setting",
                                    });
                                })
                                .catch(() => {
                                    this.$message({
                                        type: "info",
                                        message: "取消",
                                    });
                                });
                        } else if (res.code == 500 || res.code == 50014 || res.msg == '未登录') {
                            this.$store.commit("updateUserInfo", null);
                            this.$store.commit("updateToken", null);
                            this.$router.push({
                                name: "login",
                            });
                        } else {
                            console.log("关闭指引");
                            this.$store.commit("updateAccountBaseInfo", res.rows[0]);
                            setTimeout(() => {
                                this.$router.push({
                                    name: "declareShenpi",
                                });
                            }, 300);
                        }
                    })
                    .catch((err) => { });
            }
        },
        //收藏或取消收藏
        collectOrCancel() {
            if (this.userInfo) {
                let flag = this.collectItemId != "";
                if (flag) {
                    this.cancelCollect();
                } else {
                    this.collect();
                }
            } else {
                this.$router.push({
                    name: "login",
                });
            }
        },
        //查询当前是否已被收藏
        isItemCollect() {
            if (this.userInfo) {
                let identify = this.userInfo.tyshxydm;
                let id = this.itemId;
                let param = {
                    identify: identify,
                    collectionid: id,
                    type: this.collectType,
                    pageNum: 1,
                    pageSize: 10,
                };
                let url = "/dev-api/collection/list";
                // console.log("是否收藏参数：", param);
                this.$httpApi.get(url, param).then((res) => {
                    // console.log("collect:", res)
                    if (res.code == 200) {
                        if (res.rows.length > 0) {
                            this.collectItemId = res.rows[0].id;
                        } else {
                            this.collectItemId = "";
                        }
                    }
                })
                    .catch((err) => { });
            }
        },
        //收藏
        collect() {
            let id = this.itemId;
            let identify = this.userInfo.tyshxydm;
            let name = this.baseItem.itemList[0].name;
            let param = {
                collectionName: name,
                collectionId: id,
                identify: identify,
                type: this.collectType,
            };

            this.$httpApi
                .post("/dev-api/collection/install", param)
                .then((res) => {
                    console.log("添加收藏" + JSON.stringify(res));
                    if (res.code == 200 && res.data) {
                        this.collectItemId = res.id;
                    }
                })
                .catch((err) => { });
        },
        //取消收藏
        cancelCollect() {
            let collectItemId = this.collectItemId;

            this.$httpApi
                .post("/dev-api/collection/delete/" + collectItemId, {})
                .then((res) => {
                    console.log("取消收藏" + JSON.stringify(res));
                    if (res.code == 200) {
                        this.collectItemId = "";
                    }
                })
                .catch((err) => { });
        },
        /**
         * @description: 查询政策原文信息
         * @param {String} id 政策ID
         */
        getPolicySourceData(id) {
            let url = '/dev-api/cfgpermitconfig/query/data/find/cfg_permit_mappolicy';
            let params = {
                sxid: id,
            };
            this.$httpApi.post(url, params).then(res => {
                console.log('getPolicySourceData:', res);
                if (res.data && !!res.data.length) {
                    this.policySourceData = res.data[0];
                }
            }).catch(err => {
                console.log('getPolicySourceData error:', err);
            })
        },
    },
    created() {
        this.navFlag = sessionStorage.getItem("titleType")
        this.guideId = this.$route.query.id || this.storeguideId;

        let link = window.location.href;
        let collectionGuideId = "";
        let str = "collectionGuideId="
        if (link.indexOf(str) != -1) {
            let start = link.indexOf(str) + str.length
            collectionGuideId = link.substr(start, link.length)
        }
        if (collectionGuideId != "") {
            this.guideId = collectionGuideId
        }
        if(this.$route.query.linkType){
            this.linkType=this.$route.query.linkType
        }
        this.itemId = this.guideId;
        this.loadData();
    },
    mounted() {
        console.log('childItem:', this.childItem)
        document.querySelector('.header-container').style['box-shadow'] = '0 0 10px 0 rgba(9,41,77,0.25)'
        this.getPolicySourceData(this.itemId);
    },
    beforeDestroy() {
        if (document.querySelector('.header-container')) {
            document.querySelector('.header-container').style['box-shadow'] = ''
        }
    },
};
</script>

<style lang="scss" scoped>
.policy-guide {
    // padding-top: 20px;
}
.wrap-header {
    border-bottom: 1px solid $col-separator;
    margin-bottom: 20px;
    margin-top:20px;
    padding: 0 0 10px 0;
    width:63.75rem;
    .policy-name {
        margin-bottom: 10px;
        text-align: center;
        font-weight: normal;
        color: #000000;
    }
    .header_bottom {
        display: -webkit-flex;
        display: flex;
        align-items: flex-end;
    }
    .policy-infos {
        flex: 1;
        display: -webkit-flex;
        display: flex;
        justify-content: space-between;
        align-items: center;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }
    .policy-info-item {
        display: -webkit-flex;
        display: flex;
        width: 400px;

        .info-label {
            margin-right: 5px;
        }
        .info-value {
            flex: 1;
        }
    }
    .guide-btns {
        ::v-deep .el-button {
            padding-top: 0;
            padding-bottom: 0;
            height: 40px;
            vertical-align: middle;
        }
        .btn-collect {
            width: 100px;

            .collect_inner {
                display: -webkit-flex;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .collect-icon,
            .collect-text {
                vertical-align: middle;
            }

            .collect-icon {
                font-size: 18px;

                &.el-icon-star-on {
                    color: $col-yellow;
                }
            }
        }
    }
}
.wrap-content {
    width: 63.75rem;
    // display: -moz-grid;
    // display: -ms-grid;
    // display: grid;
    // grid-template-columns: calc(100% - 250px - 20px) auto;

    // .content_left {
    //     -ms-grid-column: 2;
    // }
    // .content_right {
    //     -ms-grid-column: 1;
    //     width: 250px;
    //     margin-left: 20px;
    // }
}
.guide-version {
    display: -webkit-flex;
    display: flex;
    align-items: center;

    h2 {
        margin-right: 20px;
        font-family: "Helvetica Neue", Helvetica, "PingFang SC",
            "Hiragino Sans GB", "Microsoft YaHei UI Light", "Microsoft YaHei UI",
            "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
        color: #000000;
    }
}
::v-deep .guide-card {
    margin-bottom: 20px;

    .el-card__header {
        padding: 10px;
        text-align: left;

        i {
            margin-right: 5px;
        }
    }
    .el-card__body {
        padding: 10px;
    }
}
::v-deep .card-text {
    position: relative;

    .card-text-content {
        position: relative;

        a {
            // text-decoration: underline $col-theme;
            color: $col-theme;
        }
        .content-type {
            display: inline-block;
            padding: 5px 10px;
            margin-top: 10px;
            color: #ffffff;
            background-color: $col-theme;
        }
    }

    .card-text-footer {
        display: -webkit-flex;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 10px;
        color: #999999;
        .card-text-jg {
            flex: 1;
            padding-right: 10px;
        }
    }
}
::v-deep .card-question {
    position: -webkit-sticky;
    position: sticky;
    top: 20px;
    z-index: 99;

    .question-content-item + .question-content-item {
        margin-top: 20px;
    }
    .contentItem-section {
        display: -webkit-flex;
        display: flex;

        .section-label {
            position: relative;
            display: block;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            margin-right: 10px;
            line-height: 20px;
            text-align: center;
            color: #ffffff;
            background-color: $col-theme;

            &::before {
                content: "";
                position: absolute;
                bottom: 0;
                left: 0;
                border-right: 5px solid transparent;
                border-bottom: 2px solid $col-theme;
                border-left: 5px solid transparent;
            }

            &.section-label-a {
                background-color: $col-green;

                &::before {
                    border-bottom: 2px solid $col-green;
                }
            }
        }
        .section-value {
            flex: 1;
        }
        .section-value-q {
            color: #727475;
        }
        .section-value-a {
            color: #727475;
        }
    }
    .contentItem-section + .contentItem-section {
        margin-top: 5px;
    }
}

.policy-info {
    flex: 1;
    display: -moz-grid;
    display: -ms-grid;
    display: grid;
    grid-template-columns: repeat(auto-fill, 350px);
    gap: 10px 10px;
    color: #ffffff;

    .policy-info-item {
        display: -webkit-flex;
        display: flex;

        .infoItem-label {
            margin-right: 5px;
        }
        .infoItem-value {
            flex: 1;
        }
    }
}
.banner {
  width: 100%;
  height: 180px;
  background-image: url("~@/assets/deyangImg/program.png");
  background-position-x: center;
  // padding-top: 162px;
  background-size: cover;
  background-repeat: no-repeat;
}
.policyHtml{
    .title{
        font-size: 20px;
        color: #333333;
        display: flex;
        align-items: center;
        font-weight: 700;
        margin-bottom: 20px;
    }
    .titlename{
        width: 90px;
        height: 30px;
        color: #fff;
        font-size: 18px;
        background: #D30B15;
        text-align: center;
        line-height: 30px;
    }
    .policycontent{
        font-size: 16px;
        line-height: 30px;
        color: #333333;
        font-family: '\5FAE\8F6F\96C5\9ED1', Arial, sans-serif;
    }
}
::v-deep .el-radio-button.is-active .el-radio-button__inner,
::v-deep .el-button:not([disabled]).el-button--primary:hover,
::v-deep .el-button:not([disabled]).el-button--primary {
      border-color: #3989c4;
    background-color: #3989c4;

}
</style>
